import React, { useState, useCallback, useEffect } from 'react';
import { AWS, S3 } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useDropzone } from 'react-dropzone';
import { Buffer } from 'buffer';
import { FormControl, InputLabel, MenuItem, Select, Card, CardMedia, CardContent, CardActions, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, LinearProgress, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';

window.Buffer = Buffer;

const UploadForm = ({ getCoverArtFilePath, getMediaFilesFilePath, getChannelImageFilePath, getProfilePicPath, getBackgroundPicPath, getEventImageFilePath, getPhotoGalleryPath, getChannelCategoryImageFilePath, filePurpose }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const dirName = `${process.env.REACT_APP_DIR_NAME}`;
  const listDirectoryName = `${process.env.REACT_APP_DIR_NAME}/${user.userId}`;
  const cloudFrontDomain = process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN_NAME;

  const s3 = new S3({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  });

  useEffect(() => {
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Prefix: listDirectoryName,
    };
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log("Error", err);
      } else {
        const filesWithCloudFrontUrls = data.Contents.map(file => ({
          ...file,
          CloudFrontUrl: `${cloudFrontDomain}/${file.Key}`,
        }));
        setFileList(filesWithCloudFrontUrls);
      }
    });
  }, [dirName]);

  useEffect(() => {
    switch (filePurpose) {
      case "coverArt":
        getCoverArtFilePath(selectedFiles);
        break;
      case "mediaFiles":
        getMediaFilesFilePath(selectedFiles);
        break;
      case "channelImage":
        getChannelImageFilePath(selectedFiles);
        break;
      case "profilePic":
        getProfilePicPath(selectedFiles);
        break;
      case "backgroundPic":
        getBackgroundPicPath(selectedFiles);
        break;
      case "eventImage":
        getEventImageFilePath(selectedFiles);
        break;
      case "categories":
        getChannelCategoryImageFilePath(selectedFiles);
        break;
      case "photoGallery":
        getPhotoGalleryPath(selectedFiles);
      default:
        break;
    }
  }, [selectedFiles, filePurpose]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    setUploadProgress(0); // Reset progress
    const uploadedFilePaths = [];

    const uploadPromises = files.map(file => {
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `${dirName}/${user.userId}/${file.name}`,
        Body: file,
        ContentType: file.type,
      };

      return s3.upload(params)
        .on('httpUploadProgress', (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percent);
        })
        .promise()
        .then((data) => {
          const cloudFrontUrl = `${cloudFrontDomain}/${data.Key}`;
          uploadedFilePaths.push(cloudFrontUrl);
          return true;
        })
        .catch((err) => {
          console.error("Upload error:", err);
          return false;
        });
    });

    const results = await Promise.all(uploadPromises);
    if (results.every(result => result)) {
      setFiles([]);
      setFileList(prevList => [...prevList, ...uploadedFilePaths.map((path, index) => ({
        Key: `${dirName}/${user.userId}/${files[index].name}`,
        CloudFrontUrl: path,
      }))]);

      setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...uploadedFilePaths]);
    }
  };

  const handleDelete = (key) => {
    if (key.endsWith(dirName)) {
      return;
    }
    setFileToDelete(key);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileToDelete,
    };

    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.log("Error deleting file", err);
      } else {
        setFileList(prevFiles => prevFiles.filter(file => file.Key !== fileToDelete));
      }
    });

    setDialogOpen(false);
    setFileToDelete(null);
  };

  const cancelDelete = () => {
    setDialogOpen(false);
    setFileToDelete(null);
  };

  const handleCheckboxChange = (file) => {
    setSelectedFiles(prevSelectedFiles => {
      const isSelected = prevSelectedFiles.includes(file.CloudFrontUrl);
      if (isSelected) {
        return prevSelectedFiles.filter(selectedFile => selectedFile !== file.CloudFrontUrl);
      } else {
        return [...prevSelectedFiles, file.CloudFrontUrl];
      }
    });
  };

  const getFileTypeIcon = (key) => {
    if (key.endsWith('.jpg') || key.endsWith('.jpeg') || key.endsWith('.png') || key.endsWith('.webp')) {
      return <CardMedia
        component="img"
        height="140"
        image={`${cloudFrontDomain}/${key}`}
        alt="thumbnail"
      />;
    }
    if (key.endsWith('.mp4') || key.endsWith('.mov')) {
      return <CardMedia
        component="video"
        height="140"
        controls
        src={`${cloudFrontDomain}/${key}`}
      />;
    }
    if (key.endsWith('.mp3') || key.endsWith('.m4v')) {
      return <CardMedia
        component="audio"
        height="140"
        controls
        src={`${cloudFrontDomain}/${key}`}
      />;
    }
    return <CardMedia
      component="div"
      style={{
        height: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
      }}
    >
      <DescriptionIcon style={{ fontSize: 80, color: '#aaa' }} />
    </CardMedia>;
  };

  return (
    <div className='upload-steps'>
      <h4>File Upload</h4>
      <div {...getRootProps()} style={{ height: '200px', width: '100%', borderWidth: '2px', borderColor: '#ccc', borderStyle: 'dashed', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p>}
      </div>
      <aside style={{ marginTop: 20 }}>
        <h4>Files to upload:</h4>
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </aside>
      <Button onClick={handleUpload} variant="contained" color="primary" style={{ marginTop: 20 }}>
        Upload Files
      </Button>
      {uploadProgress > 0 && (
        <div style={{ width: '100%', marginTop: 10 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" color="textSecondary">{`${uploadProgress}%`}</Typography>
        </div>
      )}
      <h4>Uploaded Files:</h4>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '12px', flexWrap: 'wrap'}}>
        {fileList.map((file, index) => (
          <Card key={index} style={{ width: '200px', marginBottom: 20 }}>
            {getFileTypeIcon(file.Key)}
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {file.Key.split('/').pop()}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton onClick={() => handleDelete(file.Key)}>
                <DeleteIcon />
              </IconButton>
              <Checkbox
                checked={selectedFiles.includes(file.CloudFrontUrl)}
                onChange={() => handleCheckboxChange(file)}
                color="primary"
              />
            </CardActions>
          </Card>
        ))}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={cancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete File</DialogTitle>
        <DialogContent>
          <Typography variant="body2">Are you sure you want to delete this file?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadForm;
