import React from 'react';
import Membership from './Membership';
import { useAuthenticator } from '@aws-amplify/ui-react';



const MembershipTiers = () => {
    const { user } = useAuthenticator((context) => [context.user]);
console.log("user email in tiers", user.signInDetails.loginId);

const tiers = [
  {
    title: 'Artist Free Membership',
    description: 'Subscribe to receive exclusive benefits and support your favorite artists',
    features: [
        {'feature':'Create a profile with your bio', 'style':'normal'}, 
        {'feature':'Share your social media direct links', 'style':'normal'}, 
        {'feature':'Upload your music', 'style':'normal'}, 
        {'feature':'Upload your pictures', 'style':'normal'},
        {'feature':'Post your upcoming Tour Dates', 'style':'normal'}, 
        {'feature':'Promote your Found Profile with your QR code', 'style':'normal'}, 
        {'feature':'Free Underground Expressions broadcast channel', 'style':'normal'} 
    ],
    price: 'Free',
    paymentLink: 'media-manager',
    userRole: 'channel_owner',
    plan: 'artist-free'
  },
//   {
//     title: 'Arist Get Gold',
//     description: 'Subscribe to receive exclusive benefits and support your favorite artists',
//     features: ['5 premium channels', 'Exclusive artist content'],
//     price: '$4.99/month',
//     paymentLink: 'https://buy.stripe.com/bIY16s2pd1vqbVSaEN',
//   },
  {
    title: 'Artist Get Platinum',
    description: 'All features from Artists Free plus...',
    features: [
        {'feature':'Message Artists for Collaborations', 'style': 'normal'},
        {'feature':'For a limited time, use promo code: FOUNDFOREVER for a 100% discount!', 'style': 'bold'}
    ],
    price: '$6.99/month',
    paymentLink: 'https://buy.stripe.com/eVaeXiaVJfmgbVS14h',
    plan: 'artist-get-platinum',
    userRole: 'channel_owner'
  },
  {
    title: 'Label Membership',
    description: 'Find the latest talent!',
    features: [
        {'feature':'Connect with Artists', 'style': 'normal'},
        {'feature':'Label Profile', 'style': 'bold'}
    ],
    price: 'Free',
    paymentLink: 'directory',
    plan: 'label-membership',
    userRole: 'label'
  }
];

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {tiers.map((tier, index) => (
        <Membership key={index} tier={tier} />
      ))}
    </div>
  );
};

export default MembershipTiers;