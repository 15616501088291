import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const EventListAdmin = ({userId, getEventList}) => {
    const dynamoDB = new DynamoDB.DocumentClient({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [events, setEvents] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState(null);

    const openDeleteDialogue = (program) => {
        setEventToDelete(program);
        setDeleteDialogOpen(true);
    }

    const closeDeleteDialogue = () => {
        setDeleteDialogOpen(false);
        setEventToDelete(null);
    };

    const handleDelete = async () => {
        if (eventToDelete) {
            // Handle the deletion logic here, e.g., call an API to delete the program from DynamoDB
            console.log("Deleting event with ID:", eventToDelete);

            // Close the dialog after deletion
            closeDeleteDialogue();
        }if (eventToDelete) {
            try {
                await deleteEvent(eventToDelete);
                // Remove the deleted program from the state to update the UI
                setEvents(events.filter(event => event.id !== eventToDelete));
                closeDeleteDialogue();
            } catch (err) {
                console.error("Failed to delete event:", err);
                // Optionally, display an error message to the user here
            }
        }
    };

    const deleteEvent = async (eventId) => {
        const params = {
            TableName: 'events',
            Key: {
                id: eventId  // Replace 'id' with your actual partition key name if different
            }
        };
    
        try {
            await dynamoDB.delete(params).promise();
            console.log(`Program with ID ${eventId} deleted successfully`);
        } catch (err) {
            console.error(`Failed to delete program with ID ${eventId}:`, err.message);
            throw err;
        }
    };

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const queryParams = {
                    TableName: 'events',
                    FilterExpression: 'event_owner = :event_owner AND event_status = :eventStatusValue',
                    ExpressionAttributeValues: {
                        ':event_owner': user.userId,
                        ':eventStatusValue': 'Active'
                    },
                };

                const result = await dynamoDB.scan(queryParams).promise();
                if (result.Items.length === 0) {
                    setError('Item not found');
                } else {
                    console.log("Events", result.Items);
                    setEvents(result.Items);
                }
            } catch (err) {
                setError(`Error fetching event: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [user.userId]); // Dependency on user.userId

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
        <List sx={{ width: '100%', maxWidth: 360, display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {events.map((eventItem, i) => {
                const startDateObj = dayjs(new Date(
                    eventItem.start_date.$y || 1970,
                    eventItem.start_date.$M ?? 0,  // Use nullish coalescing to handle 0 values
                    eventItem.start_date.$D || 1,
                    eventItem.start_date.$H || 0,
                    eventItem.start_date.$m || 0,
                    eventItem.start_date.$s || 0,
                    eventItem.start_date.$ms || 0
                ));
                
                console.log("Start Date Obj with Defaults:", startDateObj);
                const startDate = startDateObj.format('MMMM D, YYYY [at] h:mm a');
                console.log("Converted Start Date with Defaults:", startDate);
                
            return (
                <>
                    <ListItem key={eventItem.id} alignItems="flex-start" onClick={()=>getEventList(eventItem)} style={{color: '#000', padding: '0', paddingTop: '0', paddingBottom: '0', backgroundColor: '#eee', cursor: 'pointer'}}
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={(event)=>{
                                event.stopPropagation();
                                openDeleteDialogue(eventItem.id)}
                            } />
                        </IconButton>
                    }
                    >
                        <img src={eventItem.event_image[0]} style={{height: '80px'}} />
                        <ListItemText
                        style={{padding: '6px'}}
                        primary={eventItem.event_title}
                        secondary={
                            <React.Fragment>
                                <Typography
                                sx={{ display: 'block' }}
                                component="span"
                                variant="body2"
                                color="#000"
                            >
                                {eventItem.venue_name}
                            </Typography>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="#000"
                            >
                                {startDate}
                            </Typography>
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </>
            )})}
        </List>
        <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialogue}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    {"Confirm Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this program?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialogue} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default EventListAdmin;