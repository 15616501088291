import React, { useState, useEffect, useRef } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import { DynamoDB } from 'aws-sdk';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@mui/material';

const dynamoDB = new DynamoDB.DocumentClient({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});



function ResponsiveAppBar() {
    const networkId = localStorage.getItem("networkId");
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [userId, setUserId] = useState(user.userId);
    // const pages = [{'label':'Genres', 'path':'/'}, {'label':'Directory', 'path':'directory'}, {'label':'Broadcast', 'path':'broadcast'}];
    // const settings = [{'label':'Profile', 'path':`profile/${userId}`}, {'label':'Account', 'path':'account'}, {'label': 'Membership Plans', 'path':'/memberships/membership-tiers'}, {'label':'Dashboard', 'path':'dashboard'}, {'label':'Media Manager', 'path':'media-manager'}];
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [pages, setPages] = useState(null);
    const [settings, setSettings] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNewPage = (pagePath) => {
    if(pagePath.startsWith("http")) {
      window.open(pagePath);
    } else {
      navigate(pagePath);
    }
  }


  useEffect(() => {
    const fetchProfile = async () => {
        try {
            const queryParams = {
                TableName: 'networks',
                KeyConditionExpression: 'id = :id',
                ExpressionAttributeValues: {
                    ':id': networkId,
                },
            };

            const result = await dynamoDB.query(queryParams).promise();
            if (result.Items.length === 0) {
                setError('Please create your profile.');
            } else {
                const networkData = result.Items[0];
                const users = networkData.networkUsers || [];
                setPages(networkData.main_menu || []);
                setSettings(networkData.account_menu || []);
                // Check if user already exists
                const existingUserIndex = users.findIndex(u => u.userId === user.userId);
                setProfile(users[existingUserIndex]);
                console.log("profile items", users);
                console.log("Main Menu", pages);
            }
        } catch (err) {
            setError(`Error fetching profile: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    fetchProfile();
}, [userId]);

if (loading) {
    return <CircularProgress />;
}

if (error) {
    return <p>{error}</p>;
}

  return (
    <AppBar position="static" style={{backgroundColor:'rgb(38 38 38)'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img style={{maxHeight: '74px'}} src={process.env.REACT_APP_TENANT_LOGO} alt={process.env.REACTAPP_TENENT_NAME}/>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, id) => (
                <MenuItem key={page+id+Math.random()} onClick={() => handleNewPage(page.path)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={process.env.REACT_APP_TENANT_LOGO} alt={process.env.REACTAPP_TENENT_NAME}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, id) => (
              <Button
                key={page+id+Math.random()}
                onClick={() => handleNewPage(page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={profile && profile.profilePic} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={() => {
                  navigate(`profile/${userId}`)
                  handleCloseUserMenu();
                }}>
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem>
              {settings.map((setting, i) => (
                <MenuItem key={i} onClick={() => {
                  navigate(setting.path)
                  handleCloseUserMenu();
                }}>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
                <MenuItem onClick={signOut}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;