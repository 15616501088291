import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { DynamoDB } from 'aws-sdk';
import { ComponentPropsToStylePropsMap, useAuthenticator } from '@aws-amplify/ui-react';
import MediaTracksView from '../broadcast/MediaTrackView';
import MediaTrackView from '../broadcast/MediaTrackView';
import { List } from '@mui/material';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const MediaList = ({userId, onAction, filterBy}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const { user } = useAuthenticator((context) => [context.user]);
    const theme = useTheme();
    const [media, setMedia] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const queryParams = {
                    TableName: 'programs',
                    IndexName: 'ProgramOwnerIndex',
                    KeyConditionExpression: 'programOwner = :programOwner',
                    FilterExpression: 'fileType = :fileTypeValue',
                    ExpressionAttributeValues: {
                        ':programOwner': userId,
                        ":fileTypeValue": filterBy
                    },
                };

                const result = await dynamoDB.query(queryParams).promise();
                if (result.Items.length === 0) {
                    setError('There are currently no media items.');
                } else {
                    setMedia(result.Items);
                }
            } catch (err) {
                setError(`Error fetching profile: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [userId]); // Dependency on user.userId

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', cursor: 'pointer' }}>
                {media && media.map((mediaFile, i) => (
                    <MediaTrackView program={mediaFile} onAction={onAction}/>
                ))}
            </List>
        </div>
    );
};

export default MediaList;
