import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, FormControl, Autocomplete, Modal, Select, InputLabel, MenuItem } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './event-form.scss';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import UploadForm from '../storage/UploadForm';
import { FileUpload } from '@mui/icons-material';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import { v4 as uuidv4 } from 'uuid';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const EventEditForm = ({eventData}) => {
    console.log("Event Data", eventData)
    const { user } = useAuthenticator((context) => [context.user]);
    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(null);
    const [eventImage, setEventImage] = useState(null);
    const [formData, setFormData] = useState({
        event_owner: eventData?.event_owner,
        event_title: eventData?.event_title,
        event_description: eventData?.event_description,
        event_image: eventData?.event_image,
        venue_name: eventData?.venue_name,
        venue_address: eventData?.venue_address,
        venue_address_2:  eventData?.venue_address_2,
        venue_city: eventData?.venue_address_2,
        venue_state: eventData?.venue_state,
        venue_zip: eventData?.venue_zip,
        venue_country: eventData?.venue_country,
        // start_date: dayjs(eventData?.start_date),  // Initialize as dayjs object
        // end_date: dayjs(eventData?.end_date),    // Initialize as dayjs object
        invitees: eventData?.invitees,
        event_status: eventData?.event_status || ''
    });

    useEffect(() => {
        console.log("eventData in useeffect", eventData);
        if (eventData) {
            setFormData(eventData);
            console.log("event image", eventData.event_image);
            if(eventData.event_image[0] != ''){
                setEventImage(
                    eventData.event_image// ? programData.coverArt.split(',') : [] // Convert string to array
                );
            }
        }
        setPageLoading(false);
    }, [eventData]);

    const uniqueId = uuidv4();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        height: '90vh'
    };

    //Event Image
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    // Event Image Uploader
    const getEventImageFilePath = (path) => {
        console.log("Event Image File Path", path);
        setEventImage(path);
        setFormData({ ...formData, 'event_image': path });
        // handleClose();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log("Values after change", formData);
    };

    const handleStartDateChange = (newValue, fieldName) => {
        console.log("Start Date from change", newValue);
        setFormData({ ...formData, 'start_date': newValue });
        
    };

    const handleEndDateChange = (newValue, fieldName) => {
        setFormData({ ...formData, [fieldName]: newValue });
    };

    const handleMultiSelectChange = (event, value) => {
    setFormData({ ...formData, category: value });
    };

    const handleChannelMultiSelectChange = (event, value) => {
    setFormData({ ...formData, channels: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Prepare data for DynamoDB
        const dataToSubmit = {
            'event_title': formData.event_title || '',
            'event_description': formData.event_description || '',
            'event_image': formData.event_image || '',
            'venue_name': formData.venue_name || '',
            'venue_address': formData.venue_address || '',
            'venue_address_2': formData.venue_address_2 || '',
            'venue_city': formData.venue_city || '',
            'venue_state': formData.venue_state || '',
            'venue_zip': formData.venue_zip || '',
            'venue_country': formData.venue_country || '',
            'invitees': formData.invitees || '',
            'event_status': formData.event_status || '',
        };
    
        console.log('Data to submit:', dataToSubmit);
    
        try {
            // Example function to update DynamoDB
            await updateDynamoDB(dataToSubmit);
            console.log('Update successful');
        } catch (error) {
            console.error('Error updating data in DynamoDB:', error);
            // Show error message to user if needed
        }
    }

    const updateDynamoDB = async (data) => {
        const params = {
            TableName: 'events',
            Key: {
                id: formData.id, // Ensure this matches your partition key
            },
            UpdateExpression: 'set event_title = :event_title, event_description = :event_description, event_image = :event_image, venue_name = :venue_name, venue_address = :venue_address, venue_address_2 = :venue_address_2, venue_city = :venue_city, venue_state = :venue_state, venue_zip = :venue_zip, venue_country = :venue_country, invitees = :invitees, event_status = :event_status',
            ExpressionAttributeValues: {
                ':event_title': data.event_title,
                ':event_description': data.event_description,
                ':event_image': data.event_image,
                ':venue_name': data.venue_name,
                ':venue_address': data.venue_address,
                ':venue_address_2': data.venue_address_2,
                ':venue_city': data.venue_city,
                ':venue_state': data.venue_state,
                ':venue_zip': data.venue_zip,
                ':venue_country': data.venue_country,
                ':invitees': data.invitees,
                ':event_status': data.event_status
            },
            ReturnValues: 'UPDATED_NEW',
        }
        try {
            const result = await dynamoDB.update(params).promise();
            console.log('Data submitted successfully');
            
        } catch (error) {
            console.error('Error submitting data to DynamoDB', JSON.stringify(error, null, 2));
            throw error
        }
    }

    const [valueTab, setValueTab] = React.useState(0);

    const handleQuillChange = (value) => {
        setFormData({ ...formData, event_description: value });
    };

    const [selectedDate, setSelectedDate] = React.useState(dayjs());

    console.log("Event Data", eventData);

    return(
        <>
        {eventData &&
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, ma: 'auto', mt: 4}}
            onSubmit={handleSubmit}
        >
            <h3>Update This Event</h3>
            <TextField label="Title" variant="outlined" name="event_title" value={formData.event_title} onChange={handleChange} fullWidth />
            <Typography variant="h6" gutterBottom>
                Description
            </Typography>
            <ReactQuill
                value={formData.event_description}
                onChange={handleQuillChange}
                className="quill-editor"
            />

            <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Event Image</Button>
            <ul>
                {eventImage && eventImage.map((image, i) => (
                    <li><img src={image} style={{maxWidth: '80px'}} /></li>
                ))}
            </ul>

            <TextField label="Venue Name" variant="outlined" name="venue_name" value={formData.venue_name} onChange={handleChange} fullWidth />
            <TextField label="Venue Address" variant="outlined" name="venue_address" value={formData.venue_address} onChange={handleChange} fullWidth />
            <TextField label="Venue Address 2" variant="outlined" name="venue_address_2" value={formData.venue_address_2} onChange={handleChange} fullWidth />
            <TextField label="Venue City" variant="outlined" name="venue_city" value={formData.venue_city} onChange={handleChange} fullWidth />
            <TextField label="Venue State" variant="outlined" name="venue_state" value={formData.venue_state} onChange={handleChange} fullWidth />
            <TextField label="Venue Zip Code" variant="outlined" name="venue_zip" value={formData.venue_zip} onChange={handleChange} fullWidth />
            <TextField label="Venue Country" variant="outlined" name="venue_country" value={formData.venue_country} onChange={handleChange} fullWidth />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker label="Start Date of Event" value={formData.start_date} onChange={(newValue) => handleStartDateChange(newValue, 'start_date')} renderInput={(params) => <TextField {...params} />} />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker label="End Date of Event" value={formData.end_date} onChange={(newValue) => handleEndDateChange(newValue, 'end_date')} renderInput={(params) => <TextField {...params} />} />
                </DemoContainer>
            </LocalizationProvider> */}
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Event Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        name="event_status"
                        id="demo-simple-select"
                        value={formData.event_status}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem value='Active'>Active</MenuItem>
                        <MenuItem value='Pending'>Pending</MenuItem>
                        <MenuItem value='Cancelled'>Cancelled</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Button type="submit" variant="contained" color="primary">Submit</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style}>
                    <UploadForm getEventImageFilePath={getEventImageFilePath} handleClose={handleClose} filePurpose="eventImage"/>
                </Box>
            </Modal>

        </Box>}
        </>
    )
}

export default EventEditForm;