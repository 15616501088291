import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { DynamoDB } from 'aws-sdk';
import useChannelCategories from '../../hooks/useChannelCategories';
import { useNavigate } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Default theme
import '@splidejs/react-splide/css';

// or other themes
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';

// Configure AWS SDK
const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const Genres = ()=>{
    const {categories, loading, error} = useChannelCategories();
    const navigate = useNavigate();

    const handleGotoArtists = (id)=>{
        navigate(`/directory/${id}`);
    }

    const handleGotoBroadcast = (id)=>{
        navigate(`/broadcast/${id}`)
    }

    return(
        <div style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', marginTop: '24px', marginBottom: '24px', gap:'24px', backgroundColor: '#000'}}>
            <Splide aria-label="My Favorite Images"
                options={ {
                    width : '100%',
                    gap   : '1rem',
                    fixedWidth: 300,
                    type: 'slide',
                    rewind: true,
                    speed: 600,
                    focus: 'first',
                    perPage: 4,
                    perMove: 1

                } }
            >
            {categories.sort((a, b) => a.title.localeCompare(b.title)).map((category, i) => (
                
                <SplideSlide>
                    <Card key={`categories-${i}`} sx={{ maxWidth: 300 }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {category.title}
                            </Typography> 
                        </CardContent>
                        <CardMedia
                        component="img"
                        height="510"
                        width="300"
                        image={category.channel_category_image}
                        alt={category.title}
                        />
                        <CardActions style={{display: 'flex', gap: '6px', alignContent: 'space-between'}}>
                            <Button size="large" variant="contained" onClick={()=>handleGotoArtists(category.id)} style={{flexGrow: 1}}>Artists</Button>
                            <Button size="large" variant="contained" onClick={()=>handleGotoBroadcast(category.id)} style={{flexGrow: 1}}>Broadcast</Button>
                        </CardActions>
                    </Card>
                </SplideSlide>
              
            ))}
            </Splide>
        </div>
    )
};
export default Genres;