import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ArticleIcon from '@mui/icons-material/Article';
import { Icon, Button, Backdrop, CircularProgress, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './profile.scss';
import AudioList from './AudioList';
import MediaPlayer from '../broadcast/player/MediaPlayer';
import '../global/backdrop.scss';
import MediaList from './MediaList';
import EventList from './EventList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faLinkedin, faLinkedinIn, faTwitterSquare, faTiktok, faTwitter, faFacebookF, faXTwitter, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';

//QR Code
import QRCode from "react-qr-code";

//Photo Gallery
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

library.add(faFacebook);


const dynamoDB = new DynamoDB.DocumentClient({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const Profile = () => {
    const networkId = localStorage.getItem("networkId");
    const { userId: routeUserId } = useParams();
    console.log("Directory User", routeUserId);
    
    const { user } = useAuthenticator((context) => [context.user]);
    console.log("User Logged in", user.userId);
    const [userId, setUserId] = useState(routeUserId || user.userId);
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [track, setTrack] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [index, setIndex] = useState(-1)
    const playerRef = useRef(null);
    const setPlayer = (player) => {
        playerRef.current = player;
        
    };
    const navigate = useNavigate();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOpenBackdrop = () => {
        setOpenBackdrop(true);
    };

    const handleCloseBackdrop = () => {
        if (playerRef.current) {
            // playerRef.current.seekTo(0); // Optionally reset to the start
            playerRef.current.getInternalPlayer().pause(); // Pause the player
        }
        setOpenBackdrop(false);
    };

    const getEventList = (eventItem) => {
        console.log("Events", eventItem);
    }

    const getPlayerData = (trackData) => {
        setTrack(trackData);
        handleOpenBackdrop();
    };

    const handleClickSocial = (social) => {
        console.log(social);
        window.open(social);
    }

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const queryParams = {
                    TableName: 'networks',
                    KeyConditionExpression: 'id = :id',
                    ExpressionAttributeValues: {
                        ':id': networkId,
                    },
                };

                const result = await dynamoDB.query(queryParams).promise();
                if (result.Items.length === 0) {
                    navigate("media-manager");
                    // setError('Please create your profile.');
                } else {
                    
                    const networkData = result.Items[0];
                    console.log("networkData", networkData);
                    const users = networkData.networkUsers || [];
                    // Check if user already exists
                    const existingUserIndex = users.findIndex(u => {
                        console.log("User comparrison", u.userId, userId); // See the comparison
                        return u.userId === userId;
                    });
                    console.log("User ID/User Index/Users", userId, existingUserIndex, users);
                    if(existingUserIndex == -1) {
                        navigate("/media-manager");
                    } else {
                        setProfile(users[existingUserIndex]);
                    }
                }
                setUserId(routeUserId);
            } catch (err) {
                setError(`Error fetching profile: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [userId, routeUserId]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <div className="headerBar" style={{background: '#000', color: '#fff', padding: '6px'}}>
                {profile && <h1>{profile.artistBandName}</h1>}
            </div>
            <div className="bioContainer" style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', minHeight: '100vh', height: '100%', backgroundImage: `url(${profile ? encodeURI(profile.backgroundPic[0]) : process.env.REACT_APP_TENANT_BACKGROUND})`, backgroundColor: '#000', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'top center', padding: '24px', color: '#fff'}}>
                <Box className="leftCol" style={{width: '100%', maxWidth: '360px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    {profile.profilePic[0] && <div className="profilePic"><img src={encodeURI(profile.profilePic[0])} alt={profile.artistBandName} style={{width: '100%', border: '#fff 2px solid'}} /></div>}
                    <div className="quote" style={{backgroundColor: 'rgba(255,255,255,.4)', padding: '6px', fontStyle: 'italic'}}>{profile.quote}</div>
                    <div className="events" style={{backgroundColor: 'rgba(255,255,255,.4)', padding: '6px'}}>
                        <Typography variant="subtitle1">Upcoming Events</Typography>
                        <EventList userId={userId} onAction={getEventList}/>
                    </div>
                    <Box>
                        <div style={{ height: "auto", margin: "0 auto",  width: "100%" }}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={`${process.env.REACT_APP_TENANT_BASE_URL}/profile/${userId}`}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </Box>
                </Box>
                
                <div className="rightCol" style={{width: '100%', maxWidth: '360px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{backgroundColor: 'rgba(255,255,255,.4)', color: '#fff'}}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Bio
                        </Typography>
                        <ArticleIcon />
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: profile.bio }}/>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{backgroundColor: 'rgba(255,255,255,.4)', color: '#fff'}}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Music
                        </Typography>
                        <MusicNoteIcon />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MediaList userId={userId} onAction={getPlayerData} filterBy="audio" />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{backgroundColor: 'rgba(255,255,255,.4)', color: '#fff'}}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Video
                        </Typography>
                        <OndemandVideoIcon />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MediaList userId={userId} onAction={getPlayerData} filterBy="video" />
                        </AccordionDetails>
                    </Accordion>
                    <Box style={{backgroundColor: 'rgba(255,255,255,.4)', color: '#fff', display: 'flex', gap: '24px', justifyContent:'start', padding: '12px'}}>
                        {profile.instagram && <FontAwesomeIcon onClick={()=>handleClickSocial(profile.instagram)} icon={faSquareInstagram} style={{color: '#fff', fontSize: '35px', cursor: 'pointer'}} />}
                        {profile.twitter && <FontAwesomeIcon onClick={()=>handleClickSocial(profile.twitter)} icon={faXTwitter} style={{color: '#fff', fontSize: '35px', cursor: 'pointer'}} />}
                        {profile.tiktok && <FontAwesomeIcon onClick={()=>handleClickSocial(profile.tiktok)} icon={faTiktok} style={{color: '#fff', fontSize: '35px', cursor: 'pointer'}} />}
                        {profile.facebook && <FontAwesomeIcon onClick={()=>handleClickSocial(profile.facebook)} icon={faFacebookF} style={{color: '#fff', fontSize: '35px', cursor: 'pointer'}} />}
                        {profile.linkedin && <FontAwesomeIcon onClick={()=>handleClickSocial(profile.linkedin)} icon={faLinkedinIn} style={{color: '#fff', fontSize: '35px', cursor: 'pointer'}} />}
                    </Box>
                    {profile.photoGallery && <Box style={{backgroundColor: 'rgba(255,255,255,.4)', color: '#fff'}}>
                        <Typography sx={{ width: '33%', flexShrink: 0 }} style={{padding: '6px'}}>
                            Photo Gallery
                        </Typography>
                        <RowsPhotoAlbum photos={profile.photoGallery} targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                    </Box>}
                </div>
                <div style={{width: '100%', textAlign: 'right'}}>
                    <img src={process.env.REACT_APP_TENANT_LARGE_LOGO} alt={process.env.REACT_APP_TENENT_NAME} style={{width: '200px'}} />
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    style={{ backgroundColor: 'rgba(0,0,0,.9) !important', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
                    open={openBackdrop}
                    className="backdrop"
                >
                    {track && track.fileType === 'audio' && track.coverArt && (
                        <img
                            src={track.coverArt}
                            alt="Cover Art"
                            style={{ width: '50%', maxWidth: '360px', border: '2px #fff solid', marginBottom: '24px' }}
                        />
                    )}
                    {track && track.mediaFiles && (
                        <MediaPlayer
                            setPlayer={setPlayer}
                            urlPreview={track.mediaFiles}
                            playing={openBackdrop}
                            poster={track.coverArt}
                            channel={[]}
                        />
                    )}
                    <CloseIcon
                        onClick={handleCloseBackdrop}
                        style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                    />
                </Backdrop>
                <Lightbox
                    slides={profile.photoGallery}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
            </div>
            
        </>
    );
};

export default Profile;
