import { useState, useEffect } from 'react';
import AWS from 'aws-sdk'; // Import AWS SDK

const useChannels = () => {
  const [channels, setChannels] = useState([]);
  const [channelLoading, setChannelLoading] = useState(true);
  const [channelError, setChannelError] = useState(null);

  useEffect(() => {
    // Configure AWS SDK globally
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const fetchChannels = async () => {
      const dynamoDB = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: 'channels', // Your DynamoDB table name
      };

      try {
        const data = await dynamoDB.scan(params).promise();
        setChannels(data.Items || []);
      } catch (err) {
        setChannelError(err);
      } finally {
        setChannelLoading(false);
      }
    };

    fetchChannels();
  }, []);

  return { channels, channelLoading, channelError };
};

export default useChannels;