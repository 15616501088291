import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from 'react-router-dom';
import { People } from '@mui/icons-material';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}));

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const Directory = () => {
    const networkId = localStorage.getItem("networkId");
    const {category_id: routeCategoryId} = useParams();
    const [categoryId, setCategoryId] = useState(routeCategoryId);
    console.log("category id", categoryId);
    const { user } = useAuthenticator((context) => [context.user]);
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const queryParams = {
                    TableName: 'networks',
                    KeyConditionExpression: 'id = :id',
                    ExpressionAttributeValues: {
                        ':id': networkId,
                    },
                };

                const result = await dynamoDB.query(queryParams).promise();
                if (result.Items.length === 0) {
                    setError('No items found');
                } else {
                    const networkData = result.Items[0];
                    const users = networkData.networkUsers || [];
                    const filteredUsers = users.filter(user => 
                        user.genre && user.genre.some(genreItem => genreItem.id === routeCategoryId)
                    );
                    console.log("Filtered Users", filteredUsers);
    
                    setProfiles(routeCategoryId ? filteredUsers : users);
                    console.log("Profile Items", users);
                }
            } catch (err) {
                setError(`Error fetching profiles: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchProfiles();
    }, [networkId, routeCategoryId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <h1>Directory</h1>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '24px', height: '100vh'}}>
                {profiles.map((profile, i) => (
                    <Card key={`directory-${i}`} sx={{ width: 345, backgroundColor: '#eee', height: '400px' }}>
                        <CardHeader
                            avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src={profile.profilePic[0]}>
                                <People/>
                            </Avatar>
                            }
                            action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                            }
                            title={profile.artistBandName}
                            subheader={``}
                        />
                        <CardMedia
                            component="img"
                            height="194"
                            image={profile.backgroundPic[0] != '' && profile.backgroundPic[0] != null ? profile.backgroundPic[0] : process.env.REACT_APP_TENANT_BACKGROUND}
                            alt={profile.artistBandName}
                            onClick={() => navigate(`/profile/${profile.userId}`)}
                            style={{cursor: 'pointer'}}
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary" 
                            dangerouslySetInnerHTML={{ __html: profile.bio }}
                            />
                        </CardContent>
                        {/* <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                            <ShareIcon />
                            </IconButton>
                            <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                            <ExpandMoreIcon />
                            </ExpandMore>
                        </CardActions> */}
                    </Card>
                ))}
            </div>
        </>
            
    );
}

export default Directory;
