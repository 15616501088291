import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, Autocomplete, Checkbox, FormControlLabel, MenuItem, Select, FormControl, InputLabel, Modal } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './channel-manager.scss'; // Import the CSS file
import useChannelCategories from '../../hooks/useChannelCategories';
import { DynamoDB } from 'aws-sdk';
import { FileUpload } from '@mui/icons-material';
import UploadForm from '../storage/UploadForm';
import { useAuthenticator } from '@aws-amplify/ui-react';
import usePrograms from '../../hooks/usePrograms';
import CloseIcon from '@mui/icons-material/Close';

const dynamoDB = new DynamoDB.DocumentClient({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const ChannelForm = () => {
  const { user } = useAuthenticator((context) => [context.user]);
    const {categories, loading, error} = useChannelCategories();
    const {programs, programLoading, programError} = usePrograms();
    const [formData, setFormData] = useState({
    title: '',
    category: [],
    description: '',
    channel_image: '',
    broadcast_type: '',
    premium: false,
    price: '',
    frequency: '',
    programs: ''
  });

  const [channelImage, setChannelImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProgramMultiSelectChange = (event, value) => {
    setFormData((prev) => ({ ...prev, programs: value }));
  };

  const handleMultiSelectChange = (event, value) => {
    setFormData({ ...formData, category: value });
  };

  const handleQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  // Channel Image
  const [open, setOpen] = React.useState(false);
  function getChannelImageFilePath(paths) {
    console.log("Channel Image File Path", paths);
    setChannelImage(paths);
    setFormData({ ...formData, 'channel_image': paths });
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    height: '90vh'
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      TableName: 'channels', // Replace with your actual table name
      Item: {
        id: Date.now().toString(), // Unique ID for the item
        channelOwner: user.userId,
        title: formData.title,
        category: formData.category,
        description: formData.description,
        channel_image: formData.channel_image,
        broadcast_type: formData.broadcast_type,
        premium: formData.premium,
        price: formData.price,
        frequency: formData.frequency,
        programs: formData.programs
      },
    };
    try {
        await dynamoDB.put(params).promise();
        console.log('Data submitted successfully');
        // Optionally, clear the form or provide feedback to the user
        setFormData({
          title: '',
          category: [],
          description: '',
          channel_image: '',
          broadcast_type: '',
          premium: false,
          price: '',
          frequency: '',
          programs: []
        });
    } catch (error) {
        console.error('Error submitting data to DynamoDB', error);
    }
  };

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, mx: 'auto', mt: 4 }}
      onSubmit={handleSubmit}
    >
        <h3>Create a New Channel</h3>
      <TextField label="Title" variant="outlined" name="title" value={formData.title} onChange={handleChange} fullWidth />

      <Autocomplete
        multiple
        options={categories}
        getOptionLabel={(option) => option.title}
        value={formData.category}
        onChange={handleMultiSelectChange}
        renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
        fullWidth
      />

      <Box>
        <Typography variant="h6" gutterBottom>
          Description
        </Typography>
        <ReactQuill
          value={formData.description}
          onChange={handleQuillChange}
          className="quill-editor"
        />
      </Box>

      <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Channel Image</Button>
          <ul>
              {channelImage && channelImage.map((image, i) => (
                  <li key={i+Math.random(4)}><img src={image} style={{maxWidth: '80px'}} /></li>
              ))}
          </ul>

      <FormControl fullWidth>
        <InputLabel id="broadcast-type-label">Broadcast Type</InputLabel>
        <Select
          labelId="broadcast-type-label"
          label="Broadcast Type"
          name="broadcast_type"
          value={formData.broadcast_type}
          onChange={handleChange}
        >
          <MenuItem value="onDemand">onDemand</MenuItem>
          <MenuItem value="continuousPlay">continuousPlay</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Checkbox checked={formData.premium} onChange={handleCheckboxChange} name="premium" />}
        label="Premium"
      />

      {formData.premium && (
        <>
          <TextField label="Price" variant="outlined" name="price" value={formData.price} onChange={handleChange} fullWidth />

          <FormControl fullWidth>
            <InputLabel id="frequency-label">Frequency</InputLabel>
            <Select
              labelId="frequency-label"
              label="Frequency"
              name="frequency"
              value={formData.frequency}
              onChange={handleChange}
            >
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </>
      )}

      {/* <FormControl fullWidth>
        <InputLabel id="programs-label">Programs</InputLabel>
        <Select
          labelId="programs-label"
          label="Programs"
          name="programs"
          options={programs}
          value={formData.id}
          onChange={handleChange}
        >
          {programs.map((program) => (
            <MenuItem key={program.id} value={program.id}>{program.title}</MenuItem>
          ))}
        </Select>
      </FormControl> */}

      <FormControl fullWidth>
          <Autocomplete
              multiple
              options={programs}
              getOptionLabel={(option) => option.title}
              value={formData.id} // Controlled value
              onChange={handleProgramMultiSelectChange}
              renderInput={(params) => <TextField {...params} label="Programs" variant="outlined" />}
              fullWidth
          />
      </FormControl>

      <Button type="submit" variant="contained" color="primary">Submit</Button>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
      >
          <Box sx={style}>
              <CloseIcon
                  onClick={handleClose}
                  style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
              />
              <UploadForm getChannelImageFilePath={getChannelImageFilePath} filePurpose="channelImage"/>
          </Box>
      </Modal>
    </Box>
  );
};

export default ChannelForm;
