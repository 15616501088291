import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ProgramList = ({getProgramList}) => {
    const dynamoDB = new DynamoDB.DocumentClient({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [programToDelete, setProgramToDelete] = useState(null);


    const openDeleteDialogue = (program) => {
        setProgramToDelete(program);
        setDeleteDialogOpen(true);
    }

    const closeDeleteDialogue = () => {
        setDeleteDialogOpen(false);
        setProgramToDelete(null);
    };

    const handleDelete = async () => {
        if (programToDelete) {
            // Handle the deletion logic here, e.g., call an API to delete the program from DynamoDB
            console.log("Deleting program with ID:", programToDelete);

            // Close the dialog after deletion
            closeDeleteDialogue();
        }if (programToDelete) {
            try {
                await deleteProgram(programToDelete);
                // Remove the deleted program from the state to update the UI
                setPrograms(programs.filter(program => program.id !== programToDelete));
                closeDeleteDialogue();
            } catch (err) {
                console.error("Failed to delete program:", err);
                // Optionally, display an error message to the user here
            }
        }
    };

    const deleteProgram = async (programId) => {
        const params = {
            TableName: 'programs',
            Key: {
                id: programId  // Replace 'id' with your actual partition key name if different
            }
        };
    
        try {
            await dynamoDB.delete(params).promise();
            console.log(`Program with ID ${programId} deleted successfully`);
        } catch (err) {
            console.error(`Failed to delete program with ID ${programId}:`, err.message);
            throw err;
        }
    };

    useEffect(() => {
        const fetchPrograms = async () => {
            console.log("user id", user.userId);
            try {
                const queryParams = {
                    TableName: 'programs',
                    IndexName: 'ProgramOwnerIndex', 
                    KeyConditionExpression: 'programOwner = :programOwner',
                    FilterExpression: 'fileType IN (:audioType, :videoType, :interactiveType, :youtubeType, :vimeoType, :embedType)',
                    ExpressionAttributeValues: {
                        ':programOwner': user.userId,  // Replace with actual owner ID
                        ':audioType': 'audio',
                        ':videoType': 'video',
                        ':interactiveType': 'interactive',
                        ':youtubeType': 'youtube',
                        ':vimeoType': 'vimeo',
                        ':embedType': 'embed'
                    },
                };

                const result = await dynamoDB.query(queryParams).promise();
                console.log("Program Items", result);
                if (result.Items.length === 0) {
                    setError('No items found');
                } else {
                    setPrograms(result.Items);
                    // console.log("Program Items", result.Items);
                }
            } catch (err) {
                setError(`Error fetching programs: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchPrograms();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {programs.map((program, i) => (
                    <ListItem key={`program-${i}`} alignItems="flex-start" onClick={()=>getProgramList(program)} style={{cursor: 'pointer', backgroundColor: '#eee'}}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={(event)=>{
                                    event.stopPropagation();
                                    openDeleteDialogue(program.id)}
                                } />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={program.coverArt} />
                        </ListItemAvatar>
                        <ListItemText style={{color: '#000'}}
                        primary={
                            program.title
                        }
                        secondary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="#000"
                            >
                                {program.artistBandName}
                            </Typography>
                            </React.Fragment>
                        }
                        />
                        <Divider/>
                    </ListItem>
                
                ))}
            </List>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialogue}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    {"Confirm Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this program?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialogue} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default ProgramList;