/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsExports = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://bbgxcyozynbl7ig2mfqf6axtoa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-knx7k6hk4zbclcvplpsyfwdm7a",
    "aws_cognito_identity_pool_id": "us-east-1:ee452405-48bd-4b44-ac32-59c2adf350a4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kLz8j0ygX",
    "aws_user_pools_web_client_id": "3ujr6to74dhfubch36lg3if0bq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsExports;
