import React from 'react';
import MultipleSelectCheckmarks from './MultipleSelectCheckMarks';
import './MultipleSelectCheckmarks.scss';

const CaseDetail = ({caseDetail}) => {
    console.log("case details on detail", caseDetail);

    return (
        <div style={{diaplay: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{width: '240px'}}>
                Columns
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h1>Matter Style</h1>
                <MultipleSelectCheckmarks caseDetail={caseDetail} />
            </div>
        </div>
    )
}
export default CaseDetail;