import React, { useEffect, useState} from 'react';
import ReactPlayer from 'react-player/lazy'
import './media-player.scss';
import { Box, List, Typography } from '@mui/material';
import MediaTrackView from '../MediaTrackView';

const MediaPlayer = ({channel, programDefaultBackground, urlPreview, setPlayer}) => {
    console.log("CHANNEL", channel);
    const [played, setPlayed] = useState(null);
    const [duration, setDuration] = useState(0);
    const [muted, setMuted] = useState(false);
    const [volume, setVolume] = useState(null);
    const [progress, setProgress] = useState(0);
    const [playing, setPlaying] = useState(true);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [url, setUrl] = useState(null);
    const [program, setProgram] = useState(null);
    const [backgroundPosterImage, setBackgroundPosterImage] = useState(null);
    const [fileType, setFileType] = useState(null);

    const onAction = (program) => {
        console.log("Program", program);
        setUrl(program.mediaFiles);
        console.log("url", program.mediaFiles);
        setPlaying(true);
        setProgram(program);
        setBackgroundPosterImage(program.coverArt ? program.coverArt : null);
        setFileType(program.fileType);
    }    
    // if (!channel) {
    //     const channel = [];
        
    // }
    useEffect(()=>{
        // channel.push({channel_image: ''})
        // console.log("Pushed channel", channel)
        setBackgroundPosterImage(channel.channel_image ? channel.channel_image : null);
        setUrl(null);
        setProgram(null);
        setFileType(null);
        console.log("background default poster", programDefaultBackground)
    },[channel])

        return(
            <div className='player-wrapper' style={{justifyItems: 'space-around', gap: '24px', padding: '24px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 2}}>
                    <ReactPlayer 
                        className="react-player"
                        playing={playing}
                        url={url ? url : urlPreview}
                        ref={(player) => {
                            if (player) {
                              setPlayer(player); // Pass the player instance to the parent via setPlayer
                            }
                        }}
                        controls
                        width='100%'
                        style={{border: '2px solid #fff', minWidth: '360px', backgroundImage: fileType == 'audio' ? `url(${backgroundPosterImage})` : `url(${channel.channel_image}`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'top center'}}
                    />
                    {program && 
                    <>
                        <Typography variant="h6" align="center">{program.title}</Typography>
                        <Typography variant="paragraph" align="center" dangerouslySetInnerHTML={{ __html: program.description }}/>
                    </>
                    }
                </div>
                <Box className="playList" style={{width: 360}}>
                    
                    <img src={channel.channel_image} alt={channel.title} style={{width: '100%'}}/>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', cursor: 'pointer' }}>
                    {channel.programs && channel.programs.map((program, i) => (
                        <MediaTrackView program={program} onAction={()=>onAction(program)} programDefaultBackground={null} />
                    ))}
                    </List>
                </Box>
            </div>
        )

}
export default MediaPlayer;