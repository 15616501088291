import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ChannelList = ({getChannelList}) => {
    const dynamoDB = new DynamoDB.DocumentClient({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [channels, setChannels] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [channelToDelete, setChannelToDelete] = useState(null);

    const openDeleteDialogue = (program) => {
        setChannelToDelete(program);
        setDeleteDialogOpen(true);
    }

    const closeDeleteDialogue = () => {
        setDeleteDialogOpen(false);
        setChannelToDelete(null);
    };

    const handleDelete = async () => {
        if (channelToDelete) {
            // Handle the deletion logic here, e.g., call an API to delete the program from DynamoDB
            console.log("Deleting channel with ID:", channelToDelete);

            // Close the dialog after deletion
            closeDeleteDialogue();
        }if (channelToDelete) {
            try {
                await deleteChannel(channelToDelete);
                // Remove the deleted program from the state to update the UI
                setChannels(channels.filter(channel => channel.id !== channelToDelete));
                closeDeleteDialogue();
            } catch (err) {
                console.error("Failed to delete program:", err);
                // Optionally, display an error message to the user here
            }
        }
    };

    const deleteChannel = async (channelId) => {
        const params = {
            TableName: 'channels',
            Key: {
                id: channelId  // Replace 'id' with your actual partition key name if different
            }
        };
    
        try {
            await dynamoDB.delete(params).promise();
            console.log(`Channel with ID ${channelId} deleted successfully`);
        } catch (err) {
            console.error(`Failed to delete channel with ID ${channelId}:`, err.message);
            throw err;
        }
    };

    useEffect(() => {
        const fetchChannels = async () => {
            console.log("user id", user.userId);
            try {
                const queryParams = {
                    TableName: 'channels',
                    IndexName: 'channelOwner-index', 
                    KeyConditionExpression: 'channelOwner = :channelOwner',
                    // FilterExpression: 'fileType IN (:audioType, :videoType, :interactiveType, :youtubeType, :vimeoType, :embedType)',
                    ExpressionAttributeValues: {
                        ':channelOwner': user.userId,  
                    },
                };

                const result = await dynamoDB.query(queryParams).promise();
                console.log("Channel Items", result);
                if (result.Items.length === 0) {
                    setError('No items found');
                } else {
                    setChannels(result.Items);
                    // console.log("Program Items", result.Items);
                }
            } catch (err) {
                setError(`Error fetching channels: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchChannels();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                {channels.map((channel, i) => (
                    <ListItem key={`channel-${i}`} alignItems="flex-start" onClick={()=>getChannelList(channel)} style={{cursor: 'pointer', backgroundColor: '#eee'}}
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={(event)=>{
                                event.stopPropagation();
                                openDeleteDialogue(channel.id)}
                            } />
                        </IconButton>
                    }
                    >
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={channel.channel_image} />
                        </ListItemAvatar>
                        <ListItemText
                        primary={channel.title}
                        secondary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                    {channel.category.map((genre, j) => (
                                        <span>
                                        {genre.title}{(channel.category.length > 1) && <span>&nbsp; | &nbsp;</span>}
                                        </span>
                                    ))}
                            </Typography>
                            </React.Fragment>
                        }
                        />
                        <Divider/>
                    </ListItem>
                
                ))}
            </List>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialogue}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    {"Confirm Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this channel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialogue} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default ChannelList;