import React, { useState, useEffect, useContext } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Grid, Popover, Skeleton, Typography } from '@mui/material';
import './banners.module.scss';
import { ThemeContext } from '../../App';

const Banner = ({banners, purpose})=>{
    const [bannerList, setBannerList] = useState([
    //     {
    //     click_url: 'https://foundmusicapp.com',
    //     image: 'https://d1n9a9146u3kcv.cloudfront.net/public/a428f4f8-1091-706e-98db-ece85985eb93/banner1.jpg'
    // }
])

    const bannerAction = (click_url)=>{
        if (click_url.startsWith('http://') || click_url.startsWith('https://')) {
            // Open in a new tab if it's an external link
            window.open(click_url, '_blank');
        } else {
            // Open in the same tab if it's not an external link
            window.location.href = click_url;
        }
    }

    return(
        <Grid container wrap="nowrap" className="container-fluid" style={{width: '100%'}}>
            {bannerList.length > 0 && <Splide
                options={{
                    rewind: true,
                    width: 'auto',
                    type: 'slide',
                    perPage: 1,
                }}
                style={{ width: '100%' }}
            >
                {bannerList.map((item, index) => (
                    <SplideSlide
                        key={index}
                        onClick={() => bannerAction(item.click_url)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: '#eee',
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                height: 'auto',
                            }}
                            // alt={item.title}
                            src={item.image}
                        />
                    </SplideSlide>
                ))}
            </Splide>}
        </Grid>
    )
}

export default Banner;