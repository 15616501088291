import * as React from 'react';
import Banner from '../banners/Banners';
import MembershipTiers from '../memberships/MembershipTiers';
import Genres from '../genres/Genres';

const Dashboard = () => {

    return (
        <div style={{backgroundColor:'#000'}}>
            <Genres/>
        </div>
    )
}

export default Dashboard;