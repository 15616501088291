import React, { useState, useRef, useEffect } from 'react';
// import { IconButton, Slider, Box, Typography } from '@mui/material';
// import { PlayArrow, Pause, VolumeUp, VolumeOff } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const MediaTrackView = ({ program, onAction }) => {
  console.log("Programs as props", program);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);

  const triggerAction = () => {
    onAction(program);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
        audioRef.current.pause();
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue;
  };

  useEffect(()=>{
    console.log("Program", program);
  }, [program])

  return (
    <>
        <ListItem alignItems="flex-start" onClick={()=>triggerAction(program)} style={{padding: '2px'}}>
            {program.coverArt != null && <ListItemAvatar>
              <img src={program.coverArt} style={{height: '60px'}}/>
            </ListItemAvatar>}
            <ListItemText
            style={{color: '#000', paddingTop: '0 !important', paddingBottom: '0'}}
            primary={program.title}
            secondary={
                <React.Fragment>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="#000"
                    >
                        {program.artist_band_name}
                    </Typography>
                </React.Fragment>
            }
            />
        </ListItem>
        <Divider variant="inset" component="li" />
    </>
  );
};

export default MediaTrackView;
