import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { DynamoDB } from 'aws-sdk';
import useChannels from '../../hooks/useChannels';
import useProgramCategories from '../../hooks/useProgramCategories';
import { CheckCircleOutline, FileUpload } from '@mui/icons-material';
import UploadForm from '../storage/UploadForm';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const ProgramEditForm = ({ programData }) => {
    // Ensure channels is an array and has objects with title property
    console.log("program Data", programData);
    const {channels, channelLoading, channelError} = useChannels();
    const { categories, loading, error } = useProgramCategories();
    const [coverArt, setCoverArt] = useState(null); // Initialize as an empty array

    function getCoverArtFilePath(paths) {
        console.log("Cover Art File paths", paths);
        setCoverArt(paths); // Ensure paths is an array
    }

    const [mediaFiles, setMediaFiles] = useState(null); // Initialize as an empty array
    function getMediaFilesFilePath(paths) {
        console.log("Media File paths", paths);
        setMediaFiles(paths); // Ensure paths is an array
    }

    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(null);
    const [formData, setFormData] = useState({
        title: programData?.title || '',
        artist_band_name: programData?.artist_band_name || '',
        description: programData?.description || '',
        channels: programData?.channels || [],
        fileType: programData?.fileType || '',
        url: programData?.url || '',
        fileEmbed: programData?.fileEmbed || '',
        funFacts: programData?.funFacts || [''],
        coverArt: programData?.coverArt || null,
        category: programData?.category || [],
        mediaFiles: programData?.mediaFiles || null
    });
    

    useEffect(() => {
        console.log("programData in useeffect", programData);
        if (programData) {
            setFormData(programData);
            console.log("coverArt", programData.coverArt);
            if(programData.coverArt[0] != ''){
                setCoverArt(
                    programData.coverArt// ? programData.coverArt.split(',') : [] // Convert string to array
                );
            }
            if(programData.mediaFiles[0] != ''){
                setMediaFiles(
                    programData.mediaFiles// ? programData.mediaFiles.split(',') : [] // Convert string to array
                );
            }
        }
        setPageLoading(false);
    }, [programData]);

    // Modal styles
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        height: '90vh'
    };

    // Handle changes
    const handleMultiSelectChange = (event, value) => {
        setFormData({ ...formData, category: value });
    };

    const handleChannelMultiSelectChange = (event, value) => {
        setFormData((prev) => ({ ...prev, channels: value }));
    };
    

    const handleQuillChange = (value) => {
        setFormData({ ...formData, description: value });
    };

    const [alertOpen, setAlertOpen] = useState(false);

    const handleAlertOpen = () => {
        setAlertOpen(true);
        setTimeout(() => {
          handleAlertClose();
        }, 3000)
    };

    const handleAlertClose = () => {
    setAlertOpen(false);
    }

    const handleFunFactChange = (index, value) => {
        const newFunFacts = [...formData.funFacts];
        newFunFacts[index] = value;
        setFormData({ ...formData, funFacts: newFunFacts });
    };

    const addFunFact = () => {
        setFormData({ ...formData, funFacts: [...formData.funFacts, ''] });
    };

    const removeFunFact = (index) => {
        const newFunFacts = formData.funFacts.filter((_, i) => i !== index);
        setFormData({ ...formData, funFacts: newFunFacts });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Media Files
    const [openMedia, setOpenMedia] = useState(false);
    const handleOpenMedia = () => setOpenMedia(true);
    const handleCloseMedia = () => setOpenMedia(false);

    // File Uploader
    function getCoverArtFilePath(paths) {
        console.log("Cover Art File paths", paths);
        setCoverArt(paths);
    }

    // Media Files uploader
    function getMediaFilesFilePath(paths) {
        console.log("Media File paths", paths);
        setMediaFiles(paths);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Prepare data for DynamoDB
        const dataToSubmit = {
            title: formData.title || '',
            artist_band_name: formData.artist_band_name || '',
            description: formData.description || '',
            coverArt: formData.coverArt || '', // Single string
            channels: formData.channels && Array.isArray(formData.channels) ? formData.channels : [], // Array of objects
            fileType: formData.fileType || '',
            mediaFiles: formData.mediaFiles || '', // Single string
            url: formData.url || '',
            fileEmbed: formData.fileEmbed || '',
            funFacts: formData.funFacts && Array.isArray(formData.funFacts) ? formData.funFacts.map(fact => ({ S: fact })) : [], // List of strings
            category: formData.category && Array.isArray(formData.category) ? formData.category : [], // Array of objects
        };
    
        // console.log('Data to submit:', dataToSubmit);
    
        try {
            // Example function to update DynamoDB
            await updateDynamoDB(dataToSubmit);
            console.log('Update successful');
            handleAlertOpen();
        } catch (error) {
            console.error('Error updating data in DynamoDB:', error);
            // Show error message to user if needed
        }
    };

    const transformChannels = (channelsData) => {
        console.log("ChannelsData", channelsData);
        return channelsData.L.map((channel) => ({
          id: channel.M.id.S,
          title: channel.M.title?.S || '',  // Assuming title exists directly in channel.M
          broadcast_type: channel.M.broadcast_type?.S || '',
          category: channel.M.category?.L.map(cat => ({
            id: cat.M.id.S,
            title: cat.M.title.S,
            type: cat.M.type.S
          })),
          description: channel.M.description?.S || '',
          frequency: channel.M.frequency?.S || '',
          premium: channel.M.premium?.BOOL || false,
          price: channel.M.price?.S || '',
          programs: channel.M.programs?.N || 0,
        }));
    };

    // useEffect(() => {
    //     console.log("channels in useEffect", channels);
    //     const channelsFromDB = transformChannels(channels);
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         channels: channelsFromDB
    //     }));
    // }, [channels]);
    
    // Function to update DynamoDB
    const updateDynamoDB = async (data) => {
        const params = {
            TableName: 'programs',
            Key: {
                id: formData.id, // Ensure this matches your partition key
            },
            UpdateExpression: 'set title = :title, artist_band_name = :artist_band_name, description = :description, coverArt = :coverArt, channels = :channels, fileType = :fileType, mediaFiles = :mediaFiles, #u = :urlValue, fileEmbed = :fileEmbed, funFacts = :funFacts, category = :category',
            ExpressionAttributeNames: {
                '#u': 'url', // Alias for the reserved keyword
            },
            ExpressionAttributeValues: {
                ':title': data.title,
                ':artist_band_name': data.artist_band_name,
                ':description': data.description,
                ':coverArt': data.coverArt,
                ':channels': data.channels,
                ':fileType': data.fileType,
                ':mediaFiles': data.mediaFiles,
                ':urlValue': data.url,
                ':fileEmbed': data.fileEmbed,
                ':funFacts': data.funFacts,
                ':category': data.category,
            },
            ReturnValues: 'UPDATED_NEW',
        };
    
        console.log('DynamoDB params:', JSON.stringify(params, null, 2));
    
        try {
            const result = await dynamoDB.update(params).promise();
            console.log('DynamoDB update result:', result);
        } catch (error) {
            console.error('DynamoDB update error:', JSON.stringify(error, null, 2));
            throw error;
        }
    };
    
    
    
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    if (pageLoading) {
        return <p>Loading...</p>;  // Or any other loading indicator
    }

    if (pageError) {
        return <p>{pageError}</p>;
    }

    
      

    return (
        <>
            {programData && <Box
                component="form"
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, mx: 'auto', mt: 4 }}
                onSubmit={handleSubmit}
                >
                <h3>Update this Program</h3>
                <TextField label="Title" variant="outlined" name="title" value={formData.title} onChange={handleChange} fullWidth />
                <TextField label="Artist/Band Name" variant="outlined" name="artist_band_name" value={formData.artist_band_name} onChange={handleChange} fullWidth />
                <Autocomplete
                    multiple
                    options={categories}
                    getOptionLabel={(option) => option.title}
                    value={formData.category} // Controlled value
                    onChange={handleMultiSelectChange}
                    renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                    fullWidth
                />
                <Box>
                    <Typography variant="h6" gutterBottom>
                    Description
                    </Typography>
                    <ReactQuill
                    value={formData.description}
                    onChange={handleQuillChange}
                    className="quill-editor"
                    />
                </Box>

                <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Cover Art</Button>
                    <ul>
                        {coverArt && coverArt.map((image, i) => (
                            <li><img src={image} style={{maxWidth: '80px'}} /></li>
                        ))}
                    </ul>
                <FormControl fullWidth>
                    <Autocomplete
                        multiple
                        options={channels || []}
                        getOptionLabel={(option) => option.title}
                        value={formData.channels || []} // Controlled value
                        onChange={handleChannelMultiSelectChange}
                        renderInput={(params) => <TextField {...params} label="Channels" variant="outlined" />}
                        fullWidth
                    />
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="fileType-label">File Type</InputLabel>
                    <Select
                    labelId="fileType-label"
                    label="File Type"
                    name="fileType"
                    value={formData.fileType}
                    onChange={handleChange}
                    >
                    <MenuItem value="audio">Audio</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="interactive">Interactive</MenuItem>
                    <MenuItem value="youtube">YouTube</MenuItem>
                    <MenuItem value="vimeo">Vimeo</MenuItem>
                    </Select>
                </FormControl>

                <Button onClick={handleOpenMedia} variant="contained" endIcon={<FileUpload />}>Upload Media Files</Button>
                    <ul>
                        {mediaFiles && mediaFiles.map((file, i) => (
                            <li>{file}</li>
                        ))}
                    </ul>
                <TextField label="URL" variant="outlined" name="url" value={formData.url} onChange={handleChange} fullWidth />
                <TextField label="File Embed" variant="outlined" name="fileEmbed" value={formData.fileEmbed} onChange={handleChange} fullWidth multiline rows={4} />

                <Box>
                    <Typography variant="h6" gutterBottom>
                    Fun Facts
                    </Typography>
                    {formData.funFacts.map((fact, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                        <TextField
                        variant="outlined"
                        label={`Fun Fact ${index + 1}`}
                        value={fact}
                        onChange={(e) => handleFunFactChange(index, e.target.value)}
                        fullWidth
                        />
                        <IconButton onClick={() => removeFunFact(index)} disabled={formData.funFacts.length === 1}>
                        <RemoveIcon />
                        </IconButton>
                        <IconButton onClick={addFunFact}>
                        <AddIcon />
                        </IconButton>
                    </Box>
                    ))}
                </Box>

                <Button type="submit" variant="contained" color="primary">Submit</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={style}>
                        <CloseIcon
                            onClick={handleClose}
                            style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                        />
                        <UploadForm getCoverArtFilePath={getCoverArtFilePath} filePurpose="coverArt"/>
                    </Box>
                </Modal>
                <Modal
                    open={openMedia}
                    onClose={handleCloseMedia}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={style}>
                        <CloseIcon
                            onClick={handleCloseMedia}
                            style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                        />
                        <UploadForm getMediaFilesFilePath={getMediaFilesFilePath} filePurpose="mediaFiles"/>
                    </Box>
                </Modal>
                <Dialog
                    open={alertOpen}
                    onClose={handleAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
                                You program has been successfuly updated!
                        </Alert>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Box>}
        </>
    );
    
};

export default ProgramEditForm;