import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { DynamoDB } from 'aws-sdk';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const EventList = ({userId, onAction}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const { user } = useAuthenticator((context) => [context.user]);
    const theme = useTheme();
    const [events, setEvents] = useState([]);
    const convertDynamoDBDateToDayjs = (dynamoDBDate) => {
        const year = dynamoDBDate.$y.N;
        const month = dynamoDBDate.$M.N; // Note: Day.js months are 0-indexed, but DynamoDB might store 1-indexed months
        const day = dynamoDBDate.$D.N;
        const hour = dynamoDBDate.$H.N;
        const minute = dynamoDBDate.$m.N;
        const second = dynamoDBDate.$s.N;
        const millisecond = dynamoDBDate.$ms.N;
    
        // Reconstructing the Day.js object
        return dayjs({
            year: parseInt(year),
            month: parseInt(month), // Adjust if necessary (e.g., month - 1 if 1-indexed)
            day: parseInt(day),
            hour: parseInt(hour),
            minute: parseInt(minute),
            second: parseInt(second),
            millisecond: parseInt(millisecond),
        });
    };


    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const queryParams = {
                    TableName: 'events',
                    FilterExpression: 'event_owner = :event_owner AND event_status = :eventStatusValue',
                    ExpressionAttributeValues: {
                        ':event_owner': userId,
                        ':eventStatusValue': 'Active'
                    },
                };

                const result = await dynamoDB.scan(queryParams).promise();
                if (result.Items.length === 0) {
                    setError('There are currently no events.');
                } else {
                    console.log("Events", result.Items);
                    setEvents(result.Items);
                }
            } catch (err) {
                setError(`Error fetching profile: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [userId]); // Dependency on user.userId

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <List sx={{ width: '100%', maxWidth: 360, display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {events && events.map((event, i) => {
                const startDateObj = dayjs(new Date(
                    event.start_date.$y || 1970,
                    event.start_date.$M ?? 0,  // Use nullish coalescing to handle 0 values
                    event.start_date.$D || 1,
                    event.start_date.$H || 0,
                    event.start_date.$m || 0,
                    event.start_date.$s || 0,
                    event.start_date.$ms || 0
                ));
                
                console.log("Start Date Obj with Defaults:", startDateObj);
                const startDate = startDateObj.format('MMMM D, YYYY [at] h:mm a');
                console.log("Converted Start Date with Defaults:", startDate);
                
            return (
                <>
                    <ListItem key={event.id} alignItems="flex-start" onClick={onAction(event)} style={{color: '#000', padding: '0', paddingTop: '0', paddingBottom: '0', backgroundColor: '#eee'}}>
                    {/* <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={event.event_image[0]} />
                    </ListItemAvatar> */}
                    <img src={event.event_image[0]} style={{height: '80px'}} />
                    <ListItemText
                    style={{padding: '6px'}}
                    primary={event.event_title}
                    secondary={
                        <React.Fragment>
                            <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="#000"
                        >
                            {event.venue_name}
                        </Typography>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="#000"
                        >
                            {startDate}
                        </Typography>
                        </React.Fragment>
                    }
                    />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </>
            )})}
        </List>
    )

}
export default EventList;