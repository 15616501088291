import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Membership = ({ tier }) => {
    const { user } = useAuthenticator((context) => [context.user]);
    const loginId = user.signInDetails.loginId;

    const navigate = useNavigate();
  const handleButtonClick = (tier) => {
    // Replace this with the code to navigate to the detail page for the corresponding tier
    console.log("payment link", tier, loginId);
    if(tier.paymentLink == '') {
        navigate('/');
    } else if (tier.userRole == 'channel_owner') {        
        window.open(`${tier.paymentLink}?prefilled_email=${loginId}`, '_blank');
    }
  };

  return (
    <Card sx={{ maxWidth: 300, margin: '1rem', flex: '1 1 30%', minWidth: '240px', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Typography gutterBottom variant="h5" component="div">
          {tier.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {tier.description}
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Price: {tier.price}
        </Typography>
        <List sx={{ mt: 2 }}>
          {tier.features.map((features, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCheckCircle} style={{color: `${features.style == 'bold' ? '#008000' : 'inherit'}`}} />
              </ListItemIcon>
              <ListItemText style={{fontWeight: `${features.style} !important`}}>
                {features.feature}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Button variant="contained" sx={{ margin: '16px' }} onClick={() => handleButtonClick(tier)}>
        Go to Registration
      </Button>
    </Card>
  );
};

export default Membership;
