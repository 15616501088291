import { useState, useEffect } from 'react';
import AWS from 'aws-sdk'; // Import AWS SDK

const useChannelCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Configure AWS SDK globally
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const fetchCategories = async () => {
      const dynamoDB = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: 'channel_categories', // Your DynamoDB table name
      };

      try {
        const data = await dynamoDB.scan(params).promise();
        setCategories(data.Items || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading, error };
};

export default useChannelCategories;