import { Alert, Autocomplete, Box, Button, Dialog, DialogContent, DialogContentText, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import UploadForm from '../storage/UploadForm';
import { DynamoDB } from 'aws-sdk';
import useChannels from '../../hooks/useChannels';
import { CheckCircleOutline, FileUpload } from '@mui/icons-material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
import useProgramCategories from '../../hooks/useProgramCategories';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const ProgramNewForm = (props) => {
    const { children, value, index, ...other } = props;
  const { user } = useAuthenticator((context) => [context.user]);
  const {channels, channelLoading, channelError} = useChannels();
  console.log("Channels Hook", channels);
  const { categories, loading, error } = useProgramCategories();
  const [coverArt, setCoverArt] = useState(null);
  const [mediaFiles, setMediaFiles] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    artist_band_name: '',
    description: '',
    channels: '',
    fileType: '',
    url: '',
    fileEmbed: '',
    funFacts: [''],
    coverArt: '',
    category: [], // Ensure category is initialized as an empty array,
    mediaFiles: ''
  });

  const uniqueId = uuidv4();

  //Modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    height: '90vh'
  };

  //Cover Art
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Media Files
  const [openMedia, setOpenMedia] = React.useState(false);
  const handleOpenMedia = () => setOpenMedia(true);
  const handleCloseMedia = () => setOpenMedia(false);

  //File UPloader
  function getCoverArtFilePath(paths) {
    console.log("Cover Art File paths", paths);
    setCoverArt(paths);
    setFormData({ ...formData, 'coverArt': paths });
  }

  //Media Files uploader
  function getMediaFilesFilePath(paths) {
    console.log("Media File paths", paths);
    setMediaFiles(paths);
    setFormData({ ...formData, 'mediaFiles': paths });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMultiSelectChange = (event, value) => {
    setFormData({ ...formData, category: value });
  };

  const handleChannelMultiSelectChange = (event, value) => {
    setFormData({ ...formData, channels: value });
  };

  const handleQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleFunFactChange = (index, value) => {
    const newFunFacts = [...formData.funFacts];
    newFunFacts[index] = value;
    setFormData({ ...formData, funFacts: newFunFacts });
  };

  const addFunFact = () => {
    setFormData({ ...formData, funFacts: [...formData.funFacts, ''] });
  };

  const removeFunFact = (index) => {
    const newFunFacts = formData.funFacts.filter((_, i) => i !== index);
    setFormData({ ...formData, funFacts: newFunFacts });
  };

  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertOpen = () => {
      setAlertOpen(true);
      setTimeout(() => {
        handleAlertClose();
      }, 3000)
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
    // Add your submit logic here
    const params = {
        TableName: 'programs', // Replace with your actual table name
        Item: {
            id: uniqueId,
            programOwner: user.userId,
            title: formData.title,
            artist_band_name: formData.artist_band_name,
            description: formData.description,
            channels: formData.channels,
            fileType: formData.fileType,
            url: formData.url,
            fileEmbed: formData.fileEmbed,
            funFacts: formData.funFacts,
            category: formData.category,
            coverArt: formData.coverArt,
            mediaFiles: formData.mediaFiles,
        },
      };
      try {
          await dynamoDB.put(params).promise();
          console.log('Data submitted successfully');
          // Optionally, clear the form or provide feedback to the user
          setFormData({
            title: '',
            artist_band_name: '',
            description: '',
            channels: [],
            fileType: '',
            url: '',
            fileEmbed: '',
            funFacts: [''],
            category: [],
            coverArt: '',
            mediaFiles: '',
          });
          handleAlertOpen();
      } catch (error) {
          console.error('Error submitting data to DynamoDB', error);
      }
  };
    return(
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, mx: 'auto', mt: 4 }}
            onSubmit={handleSubmit}
            >
            <h3>Add a New Program</h3>
            <TextField label="Title" variant="outlined" name="title" value={formData.title} onChange={handleChange} fullWidth />
            <TextField label="Artist/Band Name" variant="outlined" name="artist_band_name" value={formData.artist_band_name} onChange={handleChange} fullWidth />
            <Autocomplete
                multiple
                options={categories}
                getOptionLabel={(option) => option.title}
                value={formData.category} // Controlled value
                onChange={handleMultiSelectChange}
                renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                fullWidth
            />
            <Box>
                <Typography variant="h6" gutterBottom>
                Description
                </Typography>
                <ReactQuill
                value={formData.description}
                onChange={handleQuillChange}
                className="quill-editor"
                />
            </Box>

            <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Cover Art</Button>
                <ul>
                    {coverArt && coverArt.map((image, i) => (
                        <li key={i+Math.random(4)}><img src={image} style={{maxWidth: '80px'}} /></li>
                    ))}
                </ul>
            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    options={channels}
                    getOptionLabel={(option) => option.title}
                    value={formData.id} // Controlled value
                    onChange={handleChannelMultiSelectChange}
                    renderInput={(params) => <TextField {...params} label="Channels" variant="outlined" />}
                    fullWidth
                />
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id="fileType-label">File Type</InputLabel>
                <Select
                labelId="fileType-label"
                label="File Type"
                name="fileType"
                value={formData.fileType}
                onChange={handleChange}
                >
                <MenuItem value="audio">Audio</MenuItem>
                <MenuItem value="video">Video</MenuItem>
                <MenuItem value="interactive">Interactive</MenuItem>
                <MenuItem value="youtube">YouTube</MenuItem>
                <MenuItem value="vimeo">Vimeo</MenuItem>
                </Select>
            </FormControl>

            <Button onClick={handleOpenMedia} variant="contained" endIcon={<FileUpload />}>Upload Media Files</Button>
                <ul>
                    {mediaFiles && mediaFiles.map((file, i) => (
                        <li>{file}</li>
                    ))}
                </ul>
            <TextField label="URL" variant="outlined" name="url" value={formData.url} onChange={handleChange} fullWidth />
            <TextField label="File Embed" variant="outlined" name="fileEmbed" value={formData.fileEmbed} onChange={handleChange} fullWidth multiline rows={4} />

            <Box>
                <Typography variant="h6" gutterBottom>
                Fun Facts
                </Typography>
                {formData.funFacts.map((fact, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <TextField
                    variant="outlined"
                    label={`Fun Fact ${index + 1}`}
                    value={fact}
                    onChange={(e) => handleFunFactChange(index, e.target.value)}
                    fullWidth
                    />
                    <IconButton onClick={() => removeFunFact(index)} disabled={formData.funFacts.length === 1}>
                    <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={addFunFact}>
                    <AddIcon />
                    </IconButton>
                </Box>
                ))}
            </Box>

            <Button type="submit" variant="contained" color="primary">Submit</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style}>
                  <CloseIcon
                        onClick={handleClose}
                        style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                    />
                  <UploadForm getCoverArtFilePath={getCoverArtFilePath} filePurpose="coverArt"/>
                </Box>
            </Modal>
            <Modal
                open={openMedia}
                onClose={handleCloseMedia}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style}>
                      <CloseIcon
                            onClick={handleCloseMedia}
                            style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                      />
                      <UploadForm getMediaFilesFilePath={getMediaFilesFilePath} filePurpose="mediaFiles"/>
                </Box>
            </Modal>
            <Dialog
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
                              You program has been successfuly created!
                      </Alert>
                  </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    )
}
export default ProgramNewForm;
