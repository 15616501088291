import { useState, useEffect } from 'react';
import AWS from 'aws-sdk'; // Import AWS SDK

const usePrograms = () => {
  const [programs, setPrograms] = useState([]);
  const [programLoading, setProgramLoading] = useState(true);
  const [programError, setProgramError] = useState(null);

  useEffect(() => {
    // Configure AWS SDK globally
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const fetchPrograms = async () => {
      const dynamoDB = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: 'programs', // Your DynamoDB table name
      };

      try {
        const data = await dynamoDB.scan(params).promise();
        setPrograms(data.Items || []);
      } catch (err) {
        setProgramError(err);
      } finally {
        setProgramLoading(false);
      }
    };

    fetchPrograms();
  }, []);

  return { programs, programLoading, programError };
};

export default usePrograms;