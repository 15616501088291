import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProgramList from './ProgramList';
import ProgramEditForm from './ProgramEditForm';
import ProgramNewForm from './ProgramNewForm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProgramForm = (props) => {
  const { children, value, index, ...other } = props;


  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const [programData, setProgramData] = useState(null);

  const getProgramList = (programList) => {
    setProgramData(programList);
    console.log("Program List on Program Manager", programList); 
  }

  console.log("ProgramData", programData);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Edit Programs" {...a11yProps(0)} />
          <Tab label="Add New Program" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={valueTab} index={0}>
        <div style={{display: 'flex'}}>
          <ProgramList getProgramList={getProgramList}/>
          <ProgramEditForm programData={programData}/>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        <ProgramNewForm/>
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={2}>
        Item Three
      </CustomTabPanel>
    </Box>
  );
};

export default ProgramForm;
